/* eslint-disable */
/* tslint:disable */
import { DateString, DateTimeString } from "@uxf/localize";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: DateTimeString; }
  JSON: { input: any; output: any; }
  Phone: { input: any; output: any; }
  UUID: { input: string; output: string; }
};

export type AuthResult = {
  __typename?: 'AuthResult';
  accessToken: Scalars['String']['output'];
};

export type Content = {
  __typename?: 'Content';
  author?: Maybe<ContentAuthor>;
  content: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Image>;
  name: Scalars['String']['output'];
  perex: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo: ContentSeo;
  tags: Array<ContentTag>;
  type: ContentType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ContentAuthor = {
  __typename?: 'ContentAuthor';
  avatar?: Maybe<Image>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
};

export type ContentSeo = {
  __typename?: 'ContentSeo';
  description: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ogDescription: Scalars['String']['output'];
  ogImage?: Maybe<Image>;
  ogTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ContentTag = {
  __typename?: 'ContentTag';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export enum ContentType {
  Blog = 'BLOG',
  LandingPage = 'LANDING_PAGE',
  Question = 'QUESTION',
  Term = 'TERM'
}

export type CustomerInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['Phone']['input'];
  surname: Scalars['String']['input'];
};

export enum Device {
  CentropolSmart = 'CENTROPOL_SMART',
  CubeIq = 'CUBE_IQ',
  Loxone = 'LOXONE'
}

export type DeviceInput = {
  device: Device;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type Image = {
  __typename?: 'Image';
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  width: Scalars['Int']['output'];
};

export enum InverterManufacturer {
  GoodWe = 'GOOD_WE',
  Growatt = 'GROWATT',
  Other = 'OTHER',
  Solax = 'SOLAX',
  Victron = 'VICTRON'
}

export type LeadInput = {
  batteryCapacity?: InputMaybe<Scalars['Float']['input']>;
  email: Scalars['String']['input'];
  inverterManufacturer: InverterManufacturer;
  inverterType: Scalars['String']['input'];
  powerPlantPerformance?: InputMaybe<Scalars['Float']['input']>;
};

export type LeadType = {
  __typename?: 'LeadType';
  annualHouseholdConsumption?: Maybe<Scalars['Float']['output']>;
  batteryCapacity?: Maybe<Scalars['Float']['output']>;
  device?: Maybe<Device>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  inverterManufacturer?: Maybe<InverterManufacturer>;
  inverterType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['Phone']['output']>;
  powerPlantPerformance?: Maybe<Scalars['Float']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
  tariff?: Maybe<Tariff>;
  uuid: Scalars['UUID']['output'];
  zip?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  authLogin: AuthResult;
  customerSet: LeadType;
  deviceSet: LeadType;
  leadCreateUpdate: LeadType;
  tariffSet: LeadType;
};


export type MutationAuthLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationCustomerSetArgs = {
  input: CustomerInput;
  lead: Scalars['UUID']['input'];
};


export type MutationDeviceSetArgs = {
  input: DeviceInput;
  lead: Scalars['UUID']['input'];
};


export type MutationLeadCreateUpdateArgs = {
  input: LeadInput;
  lead?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationTariffSetArgs = {
  input: TariffInput;
  lead?: InputMaybe<Scalars['UUID']['input']>;
};

export type Query = {
  __typename?: 'Query';
  content: Content;
  contents: Array<Content>;
  health: Scalars['Boolean']['output'];
  lead: LeadType;
  question: Question;
  questions: Array<Question>;
};


export type QueryContentArgs = {
  content: Scalars['Int']['input'];
};


export type QueryContentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  tagCodes?: Array<Scalars['String']['input']>;
  type?: InputMaybe<ContentType>;
};


export type QueryLeadArgs = {
  lead: Scalars['UUID']['input'];
};


export type QueryQuestionArgs = {
  question: Scalars['Int']['input'];
};


export type QueryQuestionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  tagCodes?: Array<Scalars['String']['input']>;
};

export type Question = {
  __typename?: 'Question';
  answer: Scalars['String']['output'];
  content: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  seo: ContentSeo;
  tags: Array<ContentTag>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum Tariff {
  Basic = 'BASIC',
  Flex = 'FLEX',
  Spot = 'SPOT'
}

export type TariffInput = {
  tariff: Tariff;
};

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', authLogin: { __typename?: 'AuthResult', accessToken: string } };

export type FileFragment = { __typename?: 'File', id: number, uuid: string, type: string, extension: string, namespace: string };

export type ImageFragment = { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number };

export type ContentAuthorFragment = { __typename?: 'ContentAuthor', id: number, firstName: string, lastName: string, avatar?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null };

export type ContentTagFragment = { __typename?: 'ContentTag', id: number, code: string, label: string };

export type ContentSeoFragment = { __typename?: 'ContentSeo', name?: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null };

export type ContentFragment = { __typename?: 'Content', id: number, type: ContentType, name: string, perex: string, hidden: boolean, publishedAt?: DateTimeString | null, createdAt: DateTimeString, updatedAt: DateTimeString, content: any, seo: { __typename?: 'ContentSeo', name?: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null }, image?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null, author?: { __typename?: 'ContentAuthor', id: number, firstName: string, lastName: string, avatar?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null } | null, tags: Array<{ __typename?: 'ContentTag', id: number, code: string, label: string }> };

export type QuestionFragment = { __typename?: 'Question', id: number, createdAt: DateTimeString, question: string, answer: string, content: any, seo: { __typename?: 'ContentSeo', name?: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null } };

export type ContentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  tagCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
  type?: InputMaybe<ContentType>;
}>;


export type ContentsQuery = { __typename?: 'Query', contents: Array<{ __typename?: 'Content', id: number, type: ContentType, name: string, perex: string, hidden: boolean, publishedAt?: DateTimeString | null, createdAt: DateTimeString, updatedAt: DateTimeString, content: any, seo: { __typename?: 'ContentSeo', name?: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null }, image?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null, author?: { __typename?: 'ContentAuthor', id: number, firstName: string, lastName: string, avatar?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null } | null, tags: Array<{ __typename?: 'ContentTag', id: number, code: string, label: string }> }> };

export type ContentQueryVariables = Exact<{
  contentId: Scalars['Int']['input'];
}>;


export type ContentQuery = { __typename?: 'Query', content: { __typename?: 'Content', id: number, type: ContentType, name: string, perex: string, hidden: boolean, publishedAt?: DateTimeString | null, createdAt: DateTimeString, updatedAt: DateTimeString, content: any, seo: { __typename?: 'ContentSeo', name?: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null }, image?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null, author?: { __typename?: 'ContentAuthor', id: number, firstName: string, lastName: string, avatar?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null } | null, tags: Array<{ __typename?: 'ContentTag', id: number, code: string, label: string }> } };

export type QuestionQueryVariables = Exact<{
  questionId: Scalars['Int']['input'];
}>;


export type QuestionQuery = { __typename?: 'Query', question: { __typename?: 'Question', id: number, createdAt: DateTimeString, question: string, answer: string, content: any, seo: { __typename?: 'ContentSeo', name?: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null } } };

export type QuestionsQueryVariables = Exact<{
  tagCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type QuestionsQuery = { __typename?: 'Query', questions: Array<{ __typename?: 'Question', id: number, createdAt: DateTimeString, question: string, answer: string, content: any, seo: { __typename?: 'ContentSeo', name?: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage?: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, namespace: string, width: number, height: number } | null } }> };

export type LeadFragment = { __typename?: 'LeadType', uuid: string };

export type LeadCreateUpdateMutationVariables = Exact<{
  lead?: InputMaybe<Scalars['UUID']['input']>;
  input: LeadInput;
}>;


export type LeadCreateUpdateMutation = { __typename?: 'Mutation', leadCreateUpdate: { __typename?: 'LeadType', uuid: string } };

export type TariffSetMutationVariables = Exact<{
  lead?: InputMaybe<Scalars['UUID']['input']>;
  input: TariffInput;
}>;


export type TariffSetMutation = { __typename?: 'Mutation', tariffSet: { __typename?: 'LeadType', uuid: string } };

export type CustomerSetMutationVariables = Exact<{
  lead: Scalars['UUID']['input'];
  input: CustomerInput;
}>;


export type CustomerSetMutation = { __typename?: 'Mutation', customerSet: { __typename?: 'LeadType', uuid: string } };

export type DeviceSetMutationVariables = Exact<{
  lead: Scalars['UUID']['input'];
  input: DeviceInput;
}>;


export type DeviceSetMutation = { __typename?: 'Mutation', deviceSet: { __typename?: 'LeadType', uuid: string } };

export const FileFragmentDoc = gql`
    fragment File on File {
  id
  uuid
  type
  extension
  namespace
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  id
  uuid
  type
  extension
  namespace
  width
  height
}
    `;
export const ContentSeoFragmentDoc = gql`
    fragment ContentSeo on ContentSeo {
  name
  title
  description
  ogTitle
  ogDescription
  ogImage {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const ContentAuthorFragmentDoc = gql`
    fragment ContentAuthor on ContentAuthor {
  id
  firstName
  lastName
  avatar {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const ContentTagFragmentDoc = gql`
    fragment ContentTag on ContentTag {
  id
  code
  label
}
    `;
export const ContentFragmentDoc = gql`
    fragment Content on Content {
  id
  type
  name
  perex
  seo {
    ...ContentSeo
  }
  hidden
  image {
    ...Image
  }
  publishedAt
  author {
    ...ContentAuthor
  }
  createdAt
  updatedAt
  content
  tags {
    ...ContentTag
  }
}
    ${ContentSeoFragmentDoc}
${ImageFragmentDoc}
${ContentAuthorFragmentDoc}
${ContentTagFragmentDoc}`;
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  id
  createdAt
  question
  answer
  seo {
    ...ContentSeo
  }
  content
}
    ${ContentSeoFragmentDoc}`;
export const LeadFragmentDoc = gql`
    fragment Lead on LeadType {
  uuid
}
    `;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  authLogin(username: $username, password: $password) {
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ContentsDocument = gql`
    query Contents($limit: Int, $offset: Int, $tagCodes: [String!]!, $type: ContentType) {
  contents(limit: $limit, offset: $offset, tagCodes: $tagCodes, type: $type) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useContentsQuery__
 *
 * To run a query within a React component, call `useContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      tagCodes: // value for 'tagCodes'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useContentsQuery(baseOptions: Apollo.QueryHookOptions<ContentsQuery, ContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentsQuery, ContentsQueryVariables>(ContentsDocument, options);
      }
export function useContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentsQuery, ContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentsQuery, ContentsQueryVariables>(ContentsDocument, options);
        }
export function useContentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContentsQuery, ContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContentsQuery, ContentsQueryVariables>(ContentsDocument, options);
        }
export type ContentsQueryHookResult = ReturnType<typeof useContentsQuery>;
export type ContentsLazyQueryHookResult = ReturnType<typeof useContentsLazyQuery>;
export type ContentsSuspenseQueryHookResult = ReturnType<typeof useContentsSuspenseQuery>;
export type ContentsQueryResult = Apollo.QueryResult<ContentsQuery, ContentsQueryVariables>;
export const ContentDocument = gql`
    query Content($contentId: Int!) {
  content(content: $contentId) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useContentQuery__
 *
 * To run a query within a React component, call `useContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useContentQuery(baseOptions: Apollo.QueryHookOptions<ContentQuery, ContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentQuery, ContentQueryVariables>(ContentDocument, options);
      }
export function useContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentQuery, ContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentQuery, ContentQueryVariables>(ContentDocument, options);
        }
export function useContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContentQuery, ContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContentQuery, ContentQueryVariables>(ContentDocument, options);
        }
export type ContentQueryHookResult = ReturnType<typeof useContentQuery>;
export type ContentLazyQueryHookResult = ReturnType<typeof useContentLazyQuery>;
export type ContentSuspenseQueryHookResult = ReturnType<typeof useContentSuspenseQuery>;
export type ContentQueryResult = Apollo.QueryResult<ContentQuery, ContentQueryVariables>;
export const QuestionDocument = gql`
    query Question($questionId: Int!) {
  question(question: $questionId) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useQuestionQuery__
 *
 * To run a query within a React component, call `useQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useQuestionQuery(baseOptions: Apollo.QueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
      }
export function useQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
        }
export function useQuestionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
        }
export type QuestionQueryHookResult = ReturnType<typeof useQuestionQuery>;
export type QuestionLazyQueryHookResult = ReturnType<typeof useQuestionLazyQuery>;
export type QuestionSuspenseQueryHookResult = ReturnType<typeof useQuestionSuspenseQuery>;
export type QuestionQueryResult = Apollo.QueryResult<QuestionQuery, QuestionQueryVariables>;
export const QuestionsDocument = gql`
    query Questions($tagCodes: [String!]!) {
  questions(limit: 999, offset: 0, tagCodes: $tagCodes) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      tagCodes: // value for 'tagCodes'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export function useQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsSuspenseQueryHookResult = ReturnType<typeof useQuestionsSuspenseQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const LeadCreateUpdateDocument = gql`
    mutation LeadCreateUpdate($lead: UUID, $input: LeadInput!) {
  leadCreateUpdate(lead: $lead, input: $input) {
    ...Lead
  }
}
    ${LeadFragmentDoc}`;
export type LeadCreateUpdateMutationFn = Apollo.MutationFunction<LeadCreateUpdateMutation, LeadCreateUpdateMutationVariables>;

/**
 * __useLeadCreateUpdateMutation__
 *
 * To run a mutation, you first call `useLeadCreateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeadCreateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leadCreateUpdateMutation, { data, loading, error }] = useLeadCreateUpdateMutation({
 *   variables: {
 *      lead: // value for 'lead'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeadCreateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<LeadCreateUpdateMutation, LeadCreateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeadCreateUpdateMutation, LeadCreateUpdateMutationVariables>(LeadCreateUpdateDocument, options);
      }
export type LeadCreateUpdateMutationHookResult = ReturnType<typeof useLeadCreateUpdateMutation>;
export type LeadCreateUpdateMutationResult = Apollo.MutationResult<LeadCreateUpdateMutation>;
export type LeadCreateUpdateMutationOptions = Apollo.BaseMutationOptions<LeadCreateUpdateMutation, LeadCreateUpdateMutationVariables>;
export const TariffSetDocument = gql`
    mutation TariffSet($lead: UUID, $input: TariffInput!) {
  tariffSet(lead: $lead, input: $input) {
    ...Lead
  }
}
    ${LeadFragmentDoc}`;
export type TariffSetMutationFn = Apollo.MutationFunction<TariffSetMutation, TariffSetMutationVariables>;

/**
 * __useTariffSetMutation__
 *
 * To run a mutation, you first call `useTariffSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTariffSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tariffSetMutation, { data, loading, error }] = useTariffSetMutation({
 *   variables: {
 *      lead: // value for 'lead'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTariffSetMutation(baseOptions?: Apollo.MutationHookOptions<TariffSetMutation, TariffSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TariffSetMutation, TariffSetMutationVariables>(TariffSetDocument, options);
      }
export type TariffSetMutationHookResult = ReturnType<typeof useTariffSetMutation>;
export type TariffSetMutationResult = Apollo.MutationResult<TariffSetMutation>;
export type TariffSetMutationOptions = Apollo.BaseMutationOptions<TariffSetMutation, TariffSetMutationVariables>;
export const CustomerSetDocument = gql`
    mutation CustomerSet($lead: UUID!, $input: CustomerInput!) {
  customerSet(lead: $lead, input: $input) {
    ...Lead
  }
}
    ${LeadFragmentDoc}`;
export type CustomerSetMutationFn = Apollo.MutationFunction<CustomerSetMutation, CustomerSetMutationVariables>;

/**
 * __useCustomerSetMutation__
 *
 * To run a mutation, you first call `useCustomerSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerSetMutation, { data, loading, error }] = useCustomerSetMutation({
 *   variables: {
 *      lead: // value for 'lead'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerSetMutation(baseOptions?: Apollo.MutationHookOptions<CustomerSetMutation, CustomerSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerSetMutation, CustomerSetMutationVariables>(CustomerSetDocument, options);
      }
export type CustomerSetMutationHookResult = ReturnType<typeof useCustomerSetMutation>;
export type CustomerSetMutationResult = Apollo.MutationResult<CustomerSetMutation>;
export type CustomerSetMutationOptions = Apollo.BaseMutationOptions<CustomerSetMutation, CustomerSetMutationVariables>;
export const DeviceSetDocument = gql`
    mutation DeviceSet($lead: UUID!, $input: DeviceInput!) {
  deviceSet(lead: $lead, input: $input) {
    ...Lead
  }
}
    ${LeadFragmentDoc}`;
export type DeviceSetMutationFn = Apollo.MutationFunction<DeviceSetMutation, DeviceSetMutationVariables>;

/**
 * __useDeviceSetMutation__
 *
 * To run a mutation, you first call `useDeviceSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceSetMutation, { data, loading, error }] = useDeviceSetMutation({
 *   variables: {
 *      lead: // value for 'lead'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeviceSetMutation(baseOptions?: Apollo.MutationHookOptions<DeviceSetMutation, DeviceSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceSetMutation, DeviceSetMutationVariables>(DeviceSetDocument, options);
      }
export type DeviceSetMutationHookResult = ReturnType<typeof useDeviceSetMutation>;
export type DeviceSetMutationResult = Apollo.MutationResult<DeviceSetMutation>;
export type DeviceSetMutationOptions = Apollo.BaseMutationOptions<DeviceSetMutation, DeviceSetMutationVariables>;