import { routeToUrl } from "@app-routes";
import { Icon } from "@uxf/ui/icon";
import Link from "next/link";

export function Footer() {
    return (
        <footer className="bg-primary-jumbotron px-4 pt-10 pb-1 text-invertedHigh space-y-10 sm:px-16">
            <div className="grid gap-10 lg:grid-cols-2">
                <h3 className="uxf-typo-h3">
                    Potřebujete poradit? <br />
                    <span className="text-centropol">Jsme tu pro vás.</span>
                </h3>
                <div className="grid gap-8 lg:pl-32">
                    <div className="flex space-x-2 sm:space-x-4">
                        <Icon className="text-white" size={44} name="phone" />
                        <div>
                            <h4 className="uxf-typo-h5 py-2">+420 705 788 091</h4>
                            <p className="uxf-typo-body2 text-bg leading-6">
                                Ve všední dny jsme na&nbsp;příjmu mezi 8:00 a&nbsp;16:00. <br />
                                Tento telefon slouží pouze pro komunikaci a&nbsp;podporu tohoto programu.
                            </p>
                        </div>
                    </div>
                    <div className="flex space-x-2 sm:space-x-4">
                        <Icon className="text-white" size={44} name="at" />
                        <div>
                            <h4 className="uxf-typo-h5 py-2">info@centropolmonitor.cz</h4>
                            <p className="uxf-typo-body2 text-bg leading-6">
                                Neradi telefonujete? Napište nám na&nbsp;e-mail – odpověď zvládáme zpravidla do
                                24&nbsp;hodin. <br />
                                Tato e-mail slouží pouze pro komunikaci a&nbsp;podporu tohoto programu.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid lg:gap-10 lg:grid-cols-2 border-t border-invertedLow">
                <div className="py-6 lg:px-0 px-1">
                    <Link href={routeToUrl("main-zone/index")}>
                        <img src="/images/logo/logo.svg" height={40} width={185} alt="Centropol" />
                    </Link>
                </div>
                <div className="md:py-6 md:flex gap-8 max-lg:px-1 lg:pl-[186px]">
                    <div className="max-md:py-6">
                        <p>technické řešení - UX Fans s.r.o.,</p>
                        <p>IČO 06596193, DIČ CZ06596193,</p>
                        <p>https://www.uxf.cz/</p>
                    </div>
                    <div className="max-md:py-6">
                        <p>Rezidence Royal</p>
                        <p>Sovova 584/2,</p>
                        <p>46014 Liberec</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
