import { Footer } from "@shared/components/footer/footer";
import { Helmet } from "@shared/components/helmet/helmet";
import { FC, PropsWithChildren } from "react";

interface Props {
    title: string;
}
export const Layout: FC<PropsWithChildren<Props>> = (props) => {
    return (
        <div>
            <Helmet title={props.title} />
            <main className="container px-4 xs:px-0">
                <div className="relative flex min-h-screen flex-col">{props.children}</div>
            </main>
            <Footer />
        </div>
    );
};
