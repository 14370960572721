import * as Sentry from "@sentry/browser";
import { flashMessage } from "@uxf/ui/flash-messages/flash-messages-service";

function logError(error: any): void {
    Sentry.captureException(error);
}

function getErrorMessage(error: any): string | undefined {
    if (error.message && error.message !== error.message) {
        return error.message;
    }
}

function handleError(error: any): void {
    const errorMessage = getErrorMessage(error);

    if (!errorMessage) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    flashMessage({ variant: "error", message: errorMessage ?? "Vyskytla se chyba." });
}

export const ErrorService = {
    logError,
    handleError,
};
