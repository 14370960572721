import { routeToUrl } from "@app-routes";
import Link from "next/link";

interface Props {
    className: string;
    logo: string;
}
export function HomepageHeader(props: Props) {
    return (
        <header className={`py-4 ${props.className}`}>
            <div className="container px-4 xs:px-0">
                <Link href={routeToUrl("main-zone/index")}>
                    <img src={`/images/logo/${props.logo}`} height={40} width={185} alt="Centropol" />
                </Link>
            </div>
        </header>
    );
}
