import { Tariff } from "@gql-schema";
import { createRouter } from "@uxf/router";

export type RouteList = {
    // main-zone
    "main-zone/index": null;
    "main-zone/sitemap-xml": null;
    "main-zone/sentry-test": null;
    "main-zone/tariff-choice": { leadUuid?: string };
    "main-zone/device-choice": { leadUuid: string };
    "main-zone/tariff-info": { tariffSlug: Tariff; leadUuid: string };
    "main-zone/thank-you-page": null;
    "main-zone/terms-and-conditions": null;
};

export default createRouter<RouteList>({
    // main-zone
    "main-zone/index": "/",
    "main-zone/sitemap-xml": "/sitemap.xml",
    "main-zone/sentry-test": "/sentry-test",
    "main-zone/tariff-choice": "/tarify/[[...leadUuid]]",
    "main-zone/device-choice": "/zarizeni/[leadUuid]",
    "main-zone/tariff-info": "/poptavka/[tariffSlug]/[leadUuid]",
    "main-zone/thank-you-page": "/dekujeme",
    "main-zone/terms-and-conditions": "/podminky",
});
