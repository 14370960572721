import { PropsWithChildren } from "react";
import styles from "./widget.module.css";
interface Props {
    className?: string;
}

export function Widget(props: PropsWithChildren<Props>) {
    return (
        <div className={props.className}>
            <div className={styles.uiWidgetOuter}>
                <div className={styles.uiWidgetInner}>{props.children}</div>
            </div>
        </div>
    );
}
